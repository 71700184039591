import React from 'react';

const GoodIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="18.5" stroke="#006470" strokeWidth="3"/>
      <circle cx="14" cy="14" r="3" fill="#006470"/>
      <circle cx="26" cy="14" r="3" fill="#006470"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4039 25C14.8197 27.1361 17.2454 28.5447 20 28.5447C22.7546 28.5447 25.1803 27.1361 26.5961 25L30 25C28.3169 28.8524 24.4728 31.5447 20 31.5447C15.5272 31.5447 11.6831 28.8524 10 25L13.4039 25Z" fill="#006470"/>
    </svg>
  );
};

export default GoodIcon;