import React from 'react';
import PropTypes from 'prop-types';
import {BadBadIcon, BadIcon, GoodGoodIcon, GoodIcon, NormalIcon} from "./icons";
import {Box, Flex} from "../../style/Grid";

const icon = {
  1: <GoodGoodIcon />,
  2: <GoodIcon />,
  3: <NormalIcon />,
  4: <BadIcon />,
  5: <BadBadIcon />,
}

const RatingIcon = ({ rating }) => {
  if (!rating) return null
  return (
    <Flex backgroundColor={"panelSecondary"} borderRadius={"10000px"} margin={"0 auto 32px"} width={"94px"} height={"94px"} alignItems={"center"} justifyContent={"center"}>
      {icon[rating]}
    </Flex>
  );
};

RatingIcon.propTypes = {
  rating: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired
};

export default RatingIcon;