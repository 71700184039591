import React from 'react';

const NormalIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="18.5" stroke="#657C88" strokeWidth="3"/>
      <circle cx="14" cy="14" r="3" fill="#657C88"/>
      <circle cx="26" cy="14" r="3" fill="#657C88"/>
      <line x1="11" y1="25.5" x2="29" y2="25.5" stroke="#657C88" strokeWidth="3"/>
    </svg>
  );
};

export default NormalIcon;