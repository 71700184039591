import React from 'react';

const SuccessIcon = () => {
  return (
    <svg width="95" height="94" viewBox="0 0 95 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="47.5" cy="47" r="47" fill="#EBEEF0"/>
      <path d="M65 41.25C65 39.7375 63.7625 38.5 62.25 38.5H48.005L49.82 29.755L49.875 29.1225C49.875 28.27 49.5175 27.5 48.9675 26.9225L47.0778 25.0328C46.9216 24.8766 46.6684 24.8766 46.5122 25.0328L33.21 38.335C32.4675 39.0775 32 40.1225 32 41.25V59.125C32 61.4075 33.8425 63.25 36.125 63.25H54.6875C56.3925 63.25 57.85 62.205 58.4825 60.7475L64.6975 46.2C64.89 45.7325 65 45.21 65 44.6875V41.25Z" stroke="#60AEA1" strokeWidth="3"/>
    </svg>
  );
};

export default SuccessIcon;