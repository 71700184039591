import React, {Fragment, useEffect, useState} from "react";
import Header from "../components/Header";
import SEO from "../components/seo";
import {Container, Flex, Wrapper} from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import Link from "../components/Link";
import {contact, URLS} from "../const/const";
import Layout from "../components/Layout";
import {Form, TextArea} from "../style/Forms";
import {useForm} from "react-hook-form";
import {Button} from "../style/Button";
import Loader from "../components/Loader";
import RatingIcon from "../components/EmailRating/RatingIcon";
import RatingTitle from "../components/EmailRating/RatingTitle";
import {navigate} from "gatsby";
import SuccessIcon from "../components/EmailRating/icons/SuccessIcon";
import NotSuccessIcon from "../components/EmailRating/icons/NotSuccessIcon";
import Cookies from 'universal-cookie';
import {v4 as uuid} from "uuid"
import queryString from "query-string";
import {isEmpty, isUndefined, parseInt} from "lodash";

const cookies = new Cookies();

const label = {
  1: "Chcete nám ještě něco vzkázat?",
  2: "Chcete nám vzkázat co bychom mohli vylepšit?",
  3: "napište nám co bychom mohli vylepšit?",
  4: "Chtěli bychom se zlepšit, napište nám prosím proč.",
  5: "Chtěli bychom se zlepšit, napište nám prosím proč.",
}

const Rating = ({ location }) => {
  const [formStatus, setFormStatus] = useState("nominal");
  const [showLoader, setShowLoader] = useState(false);
  const [queryParams, setQueryParams] = useState(null);

  const {register, handleSubmit, reset, errors, watch } = useForm();

  // send initial rating, generate uuid, save data to cookie for 1d and remove url search params
  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const data = {...searchParams, uuid: uuid()};
    const isNotEnoughDataForRating = isEmpty(searchParams) || isUndefined(searchParams.rating) || isUndefined(searchParams.email);

    if (isNotEnoughDataForRating) {
      navigate(URLS.home)
    }

    if (!cookies.get("initialRatingComplete")) {
      const sendInitialRating = async () => {
        try {
          const response = await fetch('https://www.kapitol.cz/.netlify/functions/writeToGoogleSheet', {
            method: "post",
            body: JSON.stringify(data)
          });

          await setQueryParams(data);
          if (response.ok) {
            // after rating is sent set cookie initialRatingComplete and remove search params
            navigate("/hodnoceni")
            cookies.set("initialRatingComplete", data, { path: "/", maxAge: 3600 })
          } else {
            console.error('Error: server responded with status ' + response.status)
          }
        } catch (e) {
          console.error(e);
        }
      }

      sendInitialRating();
    } else {
      setQueryParams(cookies.get("initialRatingComplete"));
    }
  }, []);
  
  
  const onSubmit = async data => {
    try {
      setShowLoader(true);
      const response = await fetch('https://www.kapitol.cz/.netlify/functions/writeToGoogleSheet', {
        method: "post",
        body: JSON.stringify({...queryParams, ...data})
      });

      if (response.ok) {
        setFormStatus("success");
        setShowLoader(false);
      } else {
        setFormStatus("fail");
        setShowLoader(false);
        console.error('Error: server responded with status ' + response.status)
      }
    } catch (e) {
      setFormStatus("fail");
      setShowLoader(false)
      console.error(e);
    }
  };
  
  if (!queryParams) return null;
  
  return (
    <Layout>
      <SEO title="Hodnocení emailove kampaně" description={"Hodnocení emailove kampaně"} meta={[{name: "robots", content: "noindex"}]} />
      <Header variant="light" />
      <Wrapper minHeight={["auto", "50vh"]}>
        <Container>
          <Flex flexDirection={"column"} margin={"0 auto"} padding={"55px 0"} maxWidth={880}>
            
            {formStatus === "fail" ? (
              <Fragment>
                <Flex justifyContent={"center"} mb={4}>
                  <NotSuccessIcon />
                </Flex>
                <Heading as={"h1"} variant={"h1"} textAlign={["center"]} mt={[0]} mb={4} fontWeight={"extraLight"} uppercase>
                  Bohužel,<br />
                  něco se pokazilo.
                </Heading>
                <Text>
                  Mužete to <Button type={"button"} variant={"asLink"} display={"inline-block"} background={"none"} border={0} onClick={() => {
                  reset()
                  setFormStatus("nominal")
                }}>zkusit znovu</Button>. Nebo nás kontaktujte na <Link color={"link"} variant={"underline"} href={`mailto:${contact.mail}`}>{contact.mail}</Link>.
                </Text>
              </Fragment>
            ) : null}

            {formStatus === "success" ? (
              <Fragment>
                <Flex justifyContent={"center"} mb={4}>
                  <SuccessIcon />
                </Flex>
                <Heading as={"h1"} variant={"h1"} textAlign={["center"]} mt={[0]} mb={4} fontWeight={"extraLight"} uppercase>
                  Děkujeme,<br />
                  Váš názor nám pomůže.
                </Heading>
              </Fragment>
            ) : null}

            {formStatus === "nominal" ? (
              <Fragment>
                <RatingIcon rating={parseInt(queryParams.rating)} />

                <RatingTitle rating={parseInt(queryParams.rating)} />

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Flex flexDirection={"column"}>
                    <Flex flexDirection="column" width={["100%"]} alignItems={"center"}>
                      <Text as={"label"} htmlFor="message" textAlign={["center"]} fontSize={2} fontWeight={400} style={{textTransform: "uppercase"}}>{label[parseInt(queryParams.rating)]}</Text>
                      <TextArea as="textarea"
                                type="text"
                                mt={"25px"}
                                mb={3}
                                maxWidth={400}
                                name="message"
                                id="message"
                                ref={register}
                                rows={5}
                      />
                    </Flex>

                    <Flex justifyContent={"center"} width={"100%"}>
                      <Button type="submit" disabled={showLoader}>
                        {showLoader ? <Loader variant={"contact"} /> : "Odeslat"}
                      </Button>
                    </Flex>
                  </Flex>

                </Form>
              </Fragment>
            ) : null}
          </Flex>
        </Container>
      </Wrapper>
    </Layout>
  )
};

export default Rating;
