import React from 'react';

const SuccessIcon = () => {
  return (
    <svg width="95" height="94" viewBox="0 0 95 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="47.5" cy="47" r="47" fill="#EBEEF0"/>
      <path d="M31 53.75C31 55.2625 32.2375 56.5 33.75 56.5L47.995 56.5L46.18 65.245L46.125 65.8775C46.125 66.73 46.4825 67.5 47.0325 68.0775L48.9222 69.9672C49.0784 70.1234 49.3316 70.1234 49.4878 69.9672L62.79 56.665C63.5325 55.9225 64 54.8775 64 53.75L64 35.875C64 33.5925 62.1575 31.75 59.875 31.75L41.3125 31.75C39.6075 31.75 38.15 32.795 37.5175 34.2525L31.3025 48.8C31.11 49.2675 31 49.79 31 50.3125L31 53.75Z" stroke="#EB5757" strokeWidth="3"/>
    </svg>
  );
};

export default SuccessIcon;