import React from 'react';

const BadIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="18.5" stroke="#D06C08" strokeWidth="3"/>
      <circle cx="14" cy="14" r="3" fill="#D06C08"/>
      <circle cx="26" cy="14" r="3" fill="#D06C08"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.5961 27.5447C25.1803 25.4085 22.7546 24 20 24C17.2454 24 14.8197 25.4085 13.4039 27.5447L10 27.5447C11.6831 23.6923 15.5272 21 20 21C24.4728 21 28.3169 23.6922 30 27.5447L26.5961 27.5447Z" fill="#D06C08"/>
    </svg>
  );
};

export default BadIcon;